import React from "react"

import Layout from "../../../components/layout"
import ContactForm from "../../../components/contactform"


import * as styles from "../../../styles/pages/about/contact.module.css"


const contactfields = [
	{
		"name": "formname",
		"label": "Name",
		"value": "",
		"required": true,
	},
	{
		"name": "formlocation",
		"label": "Address",
		"value": "",
		"type": "text",
		"required": true,
	},
	{
		"name": "formemail",
		"label": "Email",
		"type": "email",
		"value": "",
		"required": true,
	},
	{
		"name": "formphone",
		"label": "Contact No.",
		"type": "tel",
		"value": "",
		"required": true,
	},
	{
		"label": "Project Type",
		"labelname": "formprojecttype",
		"options": [
			{"code":"Building", "name": "Building"},
			{"code":"Condominium Unit", "name": "Condominium Unit"},
			{"code":"Office", "name": "Office"},
			{"code":"Residential", "name": "Residential"},
			{"code":"Warehouse", "name": "Warehouse"},
			{"code":"Others", "name": "Others"},
		],
		"labelblankmessage": "",
		"name": "formprojecttypename",
		"value": "",
		"required": true,
	},
	{
		"name": "formmsg",
		"label": "Message",
		"value": "",
		"type": "textarea",
		"required": true,
	}
];


const ContractorPage = () => {

	return (<Layout activePath='about/contact/contractor' pageTitle="Find a Contractor" pageDescription="To serve you best, we will email you the painting contractor that will match your painting requirements.">
		<div className="text-centered">
			<h1 className={"text-centered textcolor-primary"}>
				Find a Contractor
			</h1>
			<div className={"textcolor-primary "+styles.otherforminfoholder}>
				<div>To serve you best,</div> we will email you the painting contractor that will match your painting requirements.
			</div>

			<div className={styles.contactformholder+" "+styles.otherformfieldholder}>
				<ContactForm
					submitURL={"https://api.boysencdn.com/contact/boysen/contractor"}
					fields={contactfields}
				/>
			</div>
		</div>
	</Layout>);
}


export default ContractorPage
